.modal{
    border-radius: 25px;
    overflow: hidden;
    padding: 0px;
}
.responsive-image{
    width: 100%;
}
.close-icon{
    color: red;
    background-color: rgba(255,0,0,.4);
    padding: 2px;
    font-size: 20px;
    border-radius: 10px;
}
.image-section{
    min-height: 300px;
    background-color: black;
}
.title-section{
    padding: 15px 30px 0px 30px;
    text-align: start;
    font-family: Verdana, Arial, sans-serif;
    font-size: 35px;
    font-weight: bold;
}
.delete-icon{
    font-size: 25px;
}
.content-section{
    min-height: 80px;
    padding: 5px 30px 0px 30px;
    text-align: start;
    font-family: Verdana, Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
}
.footer-section{
    padding: 15px 25px 25px 25px;
}