.modal-add-news{
    border-radius: 25px;
    overflow: hidden;
    padding: 0px;
}
.close-icon-add-news{
    color: red;
    background-color: rgba(255,0,0,.4);
    padding: 2px;
    font-size: 20px;
    border-radius: 10px;
}
.content-section-add-news{
    min-height: 80px;
    padding: 5px 30px 0px 30px;
    text-align: start;
    font-family: Verdana, Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
}
.footer-section-add-news{
    padding: 15px 25px 25px 25px;
}
.title-section-add-news{
    padding: 15px 30px 0px 30px;
    text-align: start;
    font-family: Verdana, Arial, sans-serif;
    font-size: 35px;
    font-weight: bold;
}
.btn-save{
    background-color: rgba(0,0,0,.7);
    border-color: rgba(0,0,0,0);
    color: white;
    font-family: Verdana, Arial, sans-serif;
}
.btn-cancel{
    font-family: Verdana, Arial, sans-serif;
}
.text-add-news{
    font-family: Verdana, Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
}