.layer{
    padding: 20px 60px;
}
.background-new-user-btn{
    width: 100%;
    height: 50px;
}
.new-user-btn{
    float: right !important;
    padding-left: 10px;
}
.users-table{
    padding: 20px 0px;
}
@media (max-width: 600px) {
    .new-user-btn{
        float: initial !important;
        padding-top: 2px;
    }
}
@media (max-width: 350px) {
    .layer{
        padding: 30px 15px;
    }
}