.ant-carousel .slick-slide {
    text-align: center;
    height: 30em;
  
    background: #1a1818;
    overflow: hidden;
  }
  
  .ant-carousel .slick-slide h3 {
    color: #fff;
  }