.backGround {
    margin: auto;
}

.form {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 3%;
    padding: 4% 0% 0% 0%;
}

.icon{
  position: relative; 
  top: 30px; 
}

.contentRow{
    padding: 3vh 10% 3vh;
}

.login-form-forgot {
    float: right;
    margin-bottom: 10px;
}

.login-form-button {
    width: 100%;
}

@media (max-width: 600px) {
    .form{
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (max-width: 400px) {
    .contentRow{
        padding: 3vh 5% 3vh;
    }
}

