.news-panel{
    background-color: white;
    min-height: 200px;
    box-shadow: 0px 20px 20px 0px rgba(0,0,0,.3);
    border-radius: 0px 0px 30px 30px;
}
.panel-title-news{
    float: left;
    padding: 20px 0px 0px 25px;
    font-family: Verdana, Arial, sans-serif;
    font-size: 25px;
    font-weight: 600;
}
.add-icon{
    float: right;
    padding: 20px 25px 0px 0px;
    font-family: Verdana, Arial, sans-serif;
    font-size: 25px;
    font-weight: 600;
}
.panel-row-news{
    padding: 10px;
}
.panel-item-news{
    margin:10px;
    min-height: 300px;
    box-shadow: 0px 8px 10px 0px rgba(0,0,0,.3);
    border-radius: 25px ;
    overflow:hidden
}
.panel-item-col-news{
    height: 100%;
}
.panel-item-title-news{ 
    font-family: Verdana, Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    height: 100%;
    margin-right: 5px;
    padding-right: 5px;
}
.new-image{
    min-height: 200px;
    max-height: 200px;
    background-color: black;
}
.new-section{
    background-color: white;
    padding: 20px 30px 20px 30px;
}
.new-content-section{
    height: 100px;
}
.panel-item-btn-news{
    background-color: rgba(0,0,0,.7);
    border-color: rgba(0,0,0,0);
    color: white;
    font-family: Verdana, Arial, sans-serif;
}
.new-title{
    text-align: start;
    font-family: Verdana, Arial, sans-serif;
    font-size: 20px;
    font-weight: 600;
}
.new-content{
    text-align: start;
    font-family: Verdana, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.responsive-image{
    width: 100%;
}