.scheduleLayer{
    border: 1px;
    border-style: solid;
}
.scheduleHeader{
    height: 30px;
}
.scheduleItemHour{
    height: 60px;
}
.scheduleItem{
    background-color: rgba(229, 229, 229, 1);
    height: 60px;
    border: 1px;
    border-style: solid;
    border-left:    0px;
    border-bottom: 0px;
    cursor: pointer;
}

/*WEEKEND TIMES*/
.scheduleItemWeekEnd{
    height: 60px;
}
.scheduleTimeWeekEnd{
    background-color: rgba(229, 229, 229, 1);
    height: 120px;
    border: 1px;
    border-style: solid;
    border-left: 0px;
    cursor: pointer;
    z-index: 1;
}

