.courses-panel{
    background-color: white;
    min-height: 200px;
    box-shadow: 0px 20px 20px 0px rgba(0,0,0,.3);
    border-radius: 0px 0px 30px 30px;
}
.panel-title{
    float: left;
    padding: 20px 0px 0px 25px;
    font-family: Verdana, Arial, sans-serif;
    font-size: 25px;
    font-weight: 600;
}
.panel-row{
    padding: 10px;
}
.panel-item{
    margin:15px;
    height:90px;
    box-shadow: 0px 8px 10px 0px rgba(0,0,0,.3);
    border-radius: 40px ;
    padding: 0px 20px 0px 20px ;
}
.panel-item-row{
    height: 100%;
}
.panel-item-col{
    height: 100%;
}
.panel-item-title{ 
    font-family: Verdana, Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    height: 100%;
    margin-right: 5px;
    padding-right: 5px;
}
.panel-item-btn{
    background-color: rgba(255,255,255,.7);
    border-color: rgba(0,0,0,0);
    color: black;
    font-family: Verdana, Arial, sans-serif;
}
@media (max-width: 575px) {
    .panel-item{
        height:180px;
    }
    .panel-item-col{
        height: 50%;
    }
}